<template>
  <div>
    <h1>王清清论文数据采集</h1>
    <el-table :data="patient_items" stripe>
      <el-table-column prop="name" label="姓名"/>
      <el-table-column prop="age" label="年龄"/>
      <el-table-column prop="symptom" label="证型"/>
      <el-table-column prop="stage" label="治疗阶段"/>
<!--      <el-table-column prop="diag" label="诊断"/>-->
      <el-table-column style="width: 60%" prop="prescriptions" label="方剂"/>
      <el-table-column prop="note" label="备注"/>
      <el-table-column label="操作">
        <template #default="scope">
          <el-space>
            <el-button @click="handleEditorClicked(scope)" link type="primary">编辑</el-button>
            <el-button @click="handlePrescriptionRemove(scope)" type="primary" link >清空</el-button>
          </el-space>

        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :title="current_patient_name"
        v-model="editor_dialog_visible"
        width="80%"
        >
      <div v-for="(prescription,idx) in current_patient_prescriptions"
           :key="prescription"
           :style="idx%2?'background-color:skyblue':'background-color:bisque'"
           >
        <el-space>
          <span>处方名：</span>
          <el-input
            v-model="prescription.prescription_name"
            placeholder="输入处方名"
            style="width: 220px"
            clearable
            />
          <span>处方日期：</span>
          <el-date-picker
            v-model="prescription.date"
            type="date"
            placeholder="输入开具处方的时间"
            />
        </el-space>
        <div v-for="drag in prescription.drags" :key="drag">
          <el-space>
            <span>药物名：</span>
            <el-autocomplete
              style="width: 220px"
              v-model="drag.drag_name"
              clearable
              placeholder="输入药物名"
              :fetch-suggestions="querySuggestions"
              />
            <span>重量：</span>
            <el-input-number
              :min="1"
              :max="10000"
              v-model="drag.drag_weight"
              />
            <el-button type="primary" link @click="prescription.drags.splice(prescription.drags.indexOf(drag),1)">删除本条</el-button>
          </el-space>
        </div>
        <el-button
          @click="this.handleDragAdd(prescription)">
          添加药物
        </el-button>
      </div>
      <div style="height: 80px">

      </div>
      <el-button @click="this.handlePrescriptionAdd(current_patient_prescriptions)">
        添加处方
      </el-button>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleDialogConfirm">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {pinyin} from "pinyin-pro";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name:"AppVue",
  data(){
    return{
      patient_items:[
        {
          name:"PatientName",
          birth:"Date",
          diag:"Diagnosis",
          prescriptions:[{
            id:0,
            prescription_name:"DefaultName",
            date:'2020-1-1',
            drags:[
              {
                drag_name:'',
                drag_weight:1
              }
            ]
          }],
          id:0
        }
      ],
      current_patient_id:Number,
      current_patient_name:String,
      current_patient_index:Number,
      current_patient_prescriptions:[
        {
          id:0,
          prescription_name:"DefaultName",
          date:'2020-1-1',
          drags:[
            {
              drag_name:'',
              drag_weight:1
            }
          ]
        }
      ],
      editor_dialog_visible:false,
      total_drags:[{value:String}],
    }
  },
  methods:{
    handleEditorClicked(scope){
      console.log("Clicked:",scope)
      this.editor_dialog_visible=true
      //点击编辑后应展示对话框，然后设置用于对话框的各个数据
      let patient_item = this.patient_items[scope.$index]
      console.log("The patient item:",patient_item)
      this.current_patient_id = patient_item.id
      this.current_patient_name = patient_item.name
      this.current_patient_index = scope.$index
      var prescription_json
      try{
        prescription_json = JSON.parse(patient_item.prescriptions)
      }catch (e){
        console.log("Failed to parse text to json.")
        this.current_patient_prescriptions = [
          {
            id:0,
            prescription_name:"DefaultName",
            date:'2020-1-1',
            drags:[
              {
                drag_name:'',
                drag_weight:1
              }
            ]
          }
        ]
        return
      }
      if(patient_item.prescriptions === null || patient_item.prescriptions.length===0){
        console.log("The patient_item:",patient_item,"is empty")
        this.current_patient_prescriptions = [
          {
            id:0,
            prescription_name:"DefaultName",
            date:'2020-1-1',
            drags:[
              {
                drag_name:'',
                drag_weight:1
              }
            ]
          }
        ]
        return
      }
      this.current_patient_prescriptions = prescription_json
    },
    getAllPatientItems(){
      axios.get("http://wqq.wuembed.com/api/getall.php")
          .then(content=>{
            console.log("Success to get data:",content)
            if(content.data.code!==0){
              this.$prompt("message","错误，服务器返回的数据不正确")
            }
            this.patient_items = content.data.dat
            ElMessage('初始化数据加载成功')
          })
          .catch(e=>{
            console.log("Error to get all data.",e)
            this.$prompt("message","错误，未能从服务器获取数据")
          })
    },
    handleDialogConfirm(){
      const prescription_text = JSON.stringify(this.current_patient_prescriptions)
      console.log("The confirmed prescription:",prescription_text)
      axios.post("http://wqq.wuembed.com/api/upload.php",
      'id='+this.current_patient_id+'&drag='+prescription_text)
          .then(resp=>{
            console.log("Posted,The result:",resp)
          })
          .catch(e=>{
            console.log("Error: ",e)
          })
      this.patient_items[this.current_patient_index].prescriptions = prescription_text
      this.editor_dialog_visible = false
      this.getAllDrags()
      ElMessage('成功为' + this.current_patient_name + '添加处方信息')
    },

    querySuggestions(queryString,cb){
      if(this.total_drags.length===1){
        console.log("备选列表为空，退出检索")
        cb([])
        return
      }
      console.log("The input control start query " + queryString)
      var rst = this.pinyinFilter(queryString,this.total_drags)
      console.log("The query result is : " )
      console.log(rst)
      cb(rst)
    },
    handleDragAdd(prescription){
      //点击添加药物，限制：在有空药物时拒绝添加
      for (let i = 0; i < prescription.drags.length; i++) {
        if(prescription.drags[i].drag_name===''){
          console.log("处方:",prescription,"中有药物为空，拒绝添加")
          ElMessage('该处方中有药物为空，拒绝添加新药物')
          return
        }
      }
      prescription.drags.push({drag_name:'',drag_weight:1})
    },
    handlePrescriptionRemove(scope){
      console.log('try to remove prescription.',scope)
      const patient_name = this.patient_items[scope.$index].name
      const patient_id = this.patient_items[scope.$index].id
      ElMessageBox.confirm(
          'This operation will clear the data for :' + patient_name + ', Continue?',
          'Warning',
          {
            confirmButtonText:'Ok',
            cancelButtonText:'Cancel',
            type:'warning',
          }
      ).then(()=>{
        ElMessage({
          type:'success',
          message:'开始清除' + patient_name + '的处方信息',
        })
        //实际的删除操作
        axios.post("http://wqq.wuembed.com/api/upload.php",
            'id='+patient_id + '&drag='
        ).then(resp=>{
          console.log("Posted,The result:",resp)
          ElMessage({
            type:'success',
            message:'成功清除' + patient_name + '的处方信息',
          })
          this.patient_items[scope.$index].prescriptions=''
        }).catch(e=>{
          console.log("Error:",e)
        })
      }).catch(()=>{
        ElMessage({
          type:'info',
          message:'取消Remove操作',
        })
      })
    },
    handlePrescriptionAdd(prescriptions){
      for (let i = 0; i < prescriptions.length; i++) {
        if(prescriptions[i].drags.length===1){
          if(prescriptions[i].drags[0].drag_name===''){
            console.log("处方",prescriptions[i],"中并未添加药物，拒绝添加新处方")
            ElMessage('上一个处方中有药物为空，拒绝添加新处方')
            return
          }
        }
      }
      prescriptions.push({id:0,prescription_name:'DefaultName',date:'2020-1-2',drags:[{drag_name:'',drag_weight:1}]})
    },
    // 输入框搜索有关方法

    pinyinFilter (inputValue, totalList) { // 工具类方法，从数组中过滤出汉字、拼音、拼音首字母相同的项
      /* 输入内容拼音转换 */
      // 完整拼音
      const pyInput = pinyin(inputValue, { toneType: 'none', type: 'array' }).join('')
      console.log('pyf Input', pyInput)
      // 拼音首字母
      const headerPyInput = pinyin(inputValue, { pattern: 'first', type: 'array' }).join('')
      console.log('pyf HeaderInput', headerPyInput)
      return totalList.filter(e => {
        // 列表中每项的拼音
        const pyE = pinyin(e.value, { toneType: 'none', type: 'array' }).join('').slice(0, pyInput.length)
        console.log('pyE', pyE)
        // 列表中每项的拼音首字母
        const headerPyE = pinyin(e.value, { pattern: 'first', type: 'array' }).join('').slice(0, headerPyInput.length)
        console.log('headerPyE', headerPyE)
        console.log('_____________________')
        // 过滤匹配
        return e.value.includes(inputValue) || (pyE === pyInput) || (headerPyE === headerPyInput)
      })
    },
    getAllDrags(){
      console.log("Try to get all drags.")
      axios.get("http://wqq.wuembed.com/api/alldrag.php")
          .then((content)=>{
            console.log(content.data)
            if(content.data.code === 0){
              this.total_drags = content.data.dat
            }
          })
          .catch((e)=>{
            console.log("Failed to get alldrag.php",e)
          })
    }
  },
  mounted() {
    this.getAllPatientItems()
    console.log("The length of total_drags is ",this.total_drags.length)
    this.getAllDrags()
  }
}
</script>